<template>
  <div class="menu_box">
    <div class="menu" v-show="showMenu">
      <div class="logo_box">
        <Svg2Icon width="40" icon="deep-seek-copy" />
        <!-- <img class="logo" src="@/assets/img/logo.jpg" alt=""> -->
      </div>
      <div :class="['menu_item',{'menu_active':(menu_active==item.path)}]" v-for="(item,index) in menuList" :key="index" @click="toPath(item)">
        <el-tooltip class="box-item" effect="dark" :content="item.meta.title" placement="right">
          <p class="icon iconfont">{{$iconfont(item.meta.icon)}}</p>
        </el-tooltip>

        <!-- <p>{{item.meta.title}}</p> -->
      </div>
      <!-- <div class="menu_item">
        <p class="icon iconfont">&#xe64e;</p>
        <p>IT工具</p>
      </div> -->
    </div>
    <div class="logout">
      <!-- <span>退出登陆</span> -->
      <el-popover placement="right" title="" :width="100" trigger="hover" content="this is content, this is content, this is content">
        <template #default>
          <div style="text-align: center;">
            <el-link :underline="false" @click="onLogout">退出登陆</el-link>
          </div>
        </template>
        <template #reference>
          <!--  @click="showMenu = !showMenu" -->
          <div>
            <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 34.895c9.298 0 17-7.686 17-17 0-9.299-7.702-17-17.017-17C7.685.895 0 8.596 0 17.895c0 9.314 7.702 17 17 17zm0-14.302c-3.226-.016-5.727-2.715-5.727-6.27-.016-3.357 2.518-6.155 5.727-6.155 3.193 0 5.71 2.798 5.71 6.155 0 3.555-2.5 6.303-5.71 6.27zm0 11.52c-3.686 0-7.603-1.53-10.088-4.213 1.876-2.83 5.645-4.46 10.088-4.46 4.394 0 8.18 1.597 10.072 4.46-2.485 2.683-6.386 4.213-10.072 4.213z" fill="currentColor"></path>
            </svg>
          </div>
        </template>
      </el-popover>

    </div>
  </div>
</template>

<script>
import { logout } from "@/api/login";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ssoAuth } from "@/utils/ssoAuth";
export default {
  setup() {
    const store = useStore();
    let router = useRouter();
    let route = useRoute();
    let menu_active = ref("");
    let menuList = computed(() => store.state.menu.menuList);
    let showMenu = ref(true);
    let onLogout = () => {
      logout();
      // router.replace({
      //   path: '/login',
      // })
      ssoAuth();
    };
    // ref([
    //   {
    //     path:'/gpt/chat',
    //     name:"聊天",
    //     iconfont:'&#xe64e;'
    //   },
    //   {
    //     path:'/generateListing',
    //     name:"生成Listing",
    //     iconfont:'&#xe627;'
    //   },
    //   {
    //     path:'/itUtils/GenerateDatabase',
    //     name:"IT工具",
    //     iconfont:'&#xe61b;'
    //   }
    // ])

    let toPath = (item) => {
      menu_active.value = item.path;
      router.push({
        path: item.path,
      });
    };

    onMounted(() => {
      menu_active.value = route.fullPath;
    });
    return {
      showMenu,
      menuList,
      menu_active,
      toPath,
      onLogout,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu_box {
  position: relative;
  .logout {
    position: absolute;
    bottom: 16px;
    color: #ffffff;
    font-size: 14px;
    width: 56px;
    text-align: center;
    cursor: pointer;
    z-index: 100;
    // left: 0;

    svg {
      width: 34px;
      height: 34px;
    }
  }
}
.menu {
  width: 56px;
  background: var(--el-color-primary);
  color: var(--el-color-white);
  text-align: center;
  overflow: auto;
  height: calc(100vh - 40px);
  padding: 20px 0;
  .logo_box {
    margin-bottom: 30px;
    .logo {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin: 0 auto;
    }
  }

  .menu_item {
    padding: 20px 0;
    // margin-bottom: 34px;
    font-size: 14px;
    cursor: pointer;
    .icon {
      font-size: 24px;
      // margin-bottom: 8px;
    }
    &:hover {
      background-color: #4367a1;
    }
  }
  .menu_active {
    background-color: #204e97 !important;
  }
}
</style>