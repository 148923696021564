<template>
  <svg class="icon" :style="{width:width+'px',height:getHeight +'px'}">
    <use :href="'#icon-'+ icon"></use>
  </svg>
</template>

<script>
// import iconfont from "@/assets/icon/iconfont.json";
export default {
  props: {
    type: {
      type: String,
      default: "icon_id",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myIcon: "",
    };
  },
  watch: {
    // icon() {
    //   this.init();
    // },
  },
  components: {},

  computed: {
    getHeight() {
      return this.height || this.width;
    },
  },

  mounted() {
    // this.init();
  },

  methods: {
    // init() {
    //   if (this.type === "icon_id") {
    //     let glyphs = iconfont.glyphs;
    //     let icon = glyphs.find((item) => item.icon_id === this.icon);
    //     if (icon) {
    //       this.myIcon = icon.font_class;
    //     }
    //   }else{
    //     this.myIcon = this.icon;
    //   }
    // },
  },
};
</script>
<style lang='scss' scoped>
</style>