import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/reset.css"
import ElementPlus from 'element-plus'
import './assets/css/element/index.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import VMdPreview from '@kangc/v-md-editor/lib/preview';
// import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
// import '@kangc/v-md-editor/lib/style/base-editor.css';
// import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

// VMdPreview.use(vuepressTheme);

// import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
// import '@kangc/v-md-editor/lib/style/preview-html.css';
// import '@kangc/v-md-editor/lib/theme/style/vuepress.css';


import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
// import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import createCopyCodePreview from '@/utils/copyCode/preview';
import '@/utils/copyCode/copy-code.scss';


import '@kangc/v-md-editor/lib/theme/style/github.css';
import Prism from 'prismjs';

// import hljs from 'highlight.js';
import './router/getRouters'
import appConfig from '@/appConfig'
VMdPreview.use(createCopyCodePreview());

VMdPreview.use(vuepressTheme, {
  Prism,
  extend(md) {
    // md为 markdown-it 实例，可以在此处进行修改配置,并使用 plugin 进行语法扩展
    // md.set(option).use(plugin);
  },
});
// VMdPreview.use(githubTheme, {
//   Hljs: hljs,
// });
// VMdPreview.use(vuepressTheme);

const app = createApp(App)
app.use(VMdPreview)
// app.use(VMdPreviewHtml);




for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus).use(store).use(router).use(appConfig)

app.mount('#app');
