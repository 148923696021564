import { createStore } from 'vuex'
import menu from './modules/menu'
import setting from './modules/setting'
export default createStore({
  state: {
    showLogin: false,
    isLoadRouterFinished: false //路由是否加载完成，false:为加载完成，1：加载完成，2：加载失败
  },
  getters: {
  },
  mutations: {
    showLoginMutation(state,isShow){
      state.showLogin = isShow
    },
    mLoadRouterFinished(state,isLoad){
      state.isLoadRouterFinished = isLoad
    }
  },
  actions: {
  },
  modules: {
    menu,
    setting
  }
})
