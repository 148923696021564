<template>
  <!-- <Login></Login> -->

  <!-- <router-view v-slot="{ Component }" >
    <keep-alive :exclude="['Login']">
      <component :is="Component" ></component>
    </keep-alive>
  </router-view> -->
  <router-view></router-view>
</template>
<script>
// import Login from "./components/Login";

export default {
  setup() {
    return {};
  },

  components: {
    // Login,
  },
};
</script>
<style lang="scss">
body {
  // background: #f2f2f2;
}
.loginVerification {
  .el-loading-mask {
    background-color: transparent;
  }
}
.vuepress-markdown-body div[class*="v-md-pre-wrapper-"]:before {
  display: block;
  position: static !important;
  color: #ffffff !important;
  font-size: 0.8rem !important;
  background: #50505a;
  line-height: 2rem;
  padding-left: 1em;
  border-radius: 0.5em  0.5em 0 0;
}
.vuepress-markdown-body:not(.custom){
  padding: 0 !important;
}
div[data-v-md-line]{
  // background-color: red;
  // width: calc(100% - 56px);
}
@font-face {
  font-family: 'iconfont';  /* Project id 3004188 */
  src: url('//at.alicdn.com/t/c/font_3004188_cb7313o9ge.woff2?t=1741746378243') format('woff2'),
       url('//at.alicdn.com/t/c/font_3004188_cb7313o9ge.woff?t=1741746378243') format('woff'),
       url('//at.alicdn.com/t/c/font_3004188_cb7313o9ge.ttf?t=1741746378243') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// .github-markdown-body{
//   font-size:15px !important;
// }
</style>
